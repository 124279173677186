/* eslint-disable @typescript-eslint/ban-ts-comment */
import { defineStore } from 'pinia';
import { OperationResult } from '@urql/core';
import { DOCUMENTS_QUERY } from '@graphql/queries/documents';
import { DELETE_DOCUMENT } from '@graphql/mutations/document';
import {
  Document,
  Record,
  DocumentOwner,
  DocumentDeleteResponse,
  DocumentContextResponse,
} from '@graphql/types/document';
import { useFundStore } from '@stores/useFundStore';
import { useFundDrawsStore } from '@stores/useFundDrawsStore';
import { useToast } from '@mogelijk-technologies/ui-library';
import { OnfidoStatusEnum } from '@graphql/enums/onfidoStatus';
import { useUrqlStore } from '@stores/useUrqlStore';

const storeSetup = () => {
  const documentOwners = ref<DocumentOwner[]>([]);
  const documentRecords = ref<Record[]>([]);
  const commitmentAgreement = ref<Document | undefined>(undefined);
  const definitiveCommitmentAgreement = ref<Document | undefined>(undefined);
  const fundDocumentsUploaded = ref<boolean>(false);

  const { gqlClient } = toRefs(useUrqlStore());

  const setFundDocumentsUploaded = (uploaded: boolean) => {
    fundDocumentsUploaded.value = uploaded;
  };

  const setDocumentRecords = (document: Document) => {
    documentRecords.value = document.records;
  };

  const setCommitmentAgreements = () => {
    const { currentFundDraws } = toRefs(useFundDrawsStore());

    commitmentAgreement.value = currentFundDraws.value?.[0]?.documentContext[0].documentOwners[0].items.find(
      (document: Document) => document.key === 'commitment-agreement',
    );

    definitiveCommitmentAgreement.value = currentFundDraws.value?.[0].documentContext[0].documentOwners[0].items.find(
      (document: Document) => document.key === 'definitive-commitment-agreement',
    );

    if (definitiveCommitmentAgreement.value) {
      setDocumentRecords(definitiveCommitmentAgreement.value);
    }
  };

  const setDocumentOnfidoStatusDone = () => {
    const onfidoDocument = documentOwners.value[0].items.find(
      (document: Document) => document.key === 'geldig-legitimatiebewijs',
    );

    if (onfidoDocument) {
      onfidoDocument.onfido_status = OnfidoStatusEnum.DONE;
    }
  };

  const refreshDocumentContext = async (): Promise<DocumentContextResponse | undefined> => {
    const { data } = await gqlClient.value
      .query<DocumentContextResponse>(DOCUMENTS_QUERY, undefined)
      .toPromise();
    if (data) {
      const { currentFund } = useFundStore();

      const currentFundDocuments = data?.me.documentContext.find(documents => documents.fund_key === currentFund?.key);

      if (currentFundDocuments) {
        documentOwners.value = currentFundDocuments.documentOwners;
      }
      return data;
    }
    return undefined;
  };

  const deleteDocument = (document_id: string, record_id: string) => {
    gqlClient.value
      .mutation<DocumentDeleteResponse>(DELETE_DOCUMENT, {
        input: { document_id: parseInt(document_id, 10), record_id: parseInt(record_id, 10) }})
      .toPromise()
      .then((result: OperationResult<DocumentDeleteResponse>) => {
        if (result.data) {
          documentRecords.value = result.data?.deletePersonDocument[0].records;
          refreshDocumentContext();
        }
      })
      .catch(async () => {
        const toast = await useToast({
          message: 'Er iets misgegaan bij het verwijderen van het bestand probeer het alstublieft opnieuw.',
          duration: 12e4,
          theme: 'error',
        });

        toast.present();
      });
  };

  return {
    // state
    documentOwners,
    documentRecords,
    commitmentAgreement,
    definitiveCommitmentAgreement,
    fundDocumentsUploaded,

    // setters
    setFundDocumentsUploaded,
    setCommitmentAgreements,
    setDocumentOnfidoStatusDone,
    setDocumentRecords,

    // actions
    refreshDocumentContext,
    deleteDocument,
  };
}

export const useDocumentsStore = defineStore('useDocumentsStore', storeSetup, { persistedState: { persist: false } });
