import { gql } from '@urql/core';

export const DOCUMENTS_QUERY = gql`
  query Me {
    me {
      documentContext {
        fund_key
        documentOwners {
          owner {
            ... on Person {
              id
              name
            }
            ... on Company {
              id
              name
              coc_number
              __typename
            }
          }
          items {
            id
            key
            name
            type
            visible_for_customer
            onfido_status
            maxSize
            template {
              absolute_url
              created_at
              extension
              filename
              id
            }
            records {
              asset {
                absolute_url
                created_at
                extension
                filename
                id
                updated_at
                filesize {
                  human
                  raw
                }
              }
              created_at
              id
              name
              updated_at
              downloadable_for_customer
            }
          }
        }
      }
    }
  }
`;
