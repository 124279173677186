<script setup lang="ts">
import { AppButton } from '@mogelijk-technologies/ui-library';
import FundFooter from '@components/Fund/FundFooter.vue';
import { useFundDrawsStore } from '@stores/useFundDrawsStore';
import { useFundRegistrationsStore } from '@stores/useFundRegistrations';
import { useFundStore } from '@stores/useFundStore';
import { useDocumentsStore } from '@stores/useDocumentsStore';

const { shouldSign, shouldPayOrPayed } = toRefs(useFundDrawsStore());
const { currentFund } = toRefs(useFundStore());
const { fundDocumentsUploaded } = toRefs(useDocumentsStore());
const { hasIncompleteRegistrations } = useFundRegistrationsStore();

const route = useRoute();
const router = useRouter();

const nextStepClicked = async () => {
  if (route.path.includes('documenten')) {
    router.push(`/mogelijk-fonds/${route.params.id}/inschrijving`);
  } else if (route.path.includes('deelname')) {
    router.push(`/mogelijk-fonds/${route.params.id}/documenten`);
  }
};

const previousStepClicked = () => {
  if (route.path.includes('documenten')) {
    router.push(`/mogelijk-fonds/${route.params.id}/deelname`);
  } else if (route.path.includes('inschrijving')) {
    router.push(`/mogelijk-fonds/${route.params.id}/documenten`);
  } else {
    router.push('/mogelijk-fonds');
  }
};

const isButtonDisabled = computed(() => {
  if (route.path.includes('deelname')) {
    return hasIncompleteRegistrations(route.params.id as string);
  }

  if (route.path.includes('documenten')) {
    return !fundDocumentsUploaded.value;
  }
  return false;
});
</script>

<template>
  <div class="flex h-full flex-col justify-between">
    <slot />
    <div>
      <div
        v-if="!shouldSign && !shouldPayOrPayed"
        class="mb-4 flex w-full flex-col-reverse flex-wrap items-center justify-between justify-self-end sm:my-4
                  sm:h-16 sm:flex-row sm:border-t-2 sm:border-blue-50 sm:pt-4"
      >
        <NuxtLink
          v-if="route.path.includes('deelname')"
          data-test-id="fund-cancel"
          to="/mogelijk-fonds"
          class="my-4 w-full text-center text-blue-900 hover:underline sm:my-0 sm:w-fit"
          title="Annuleren"
        >
          <p>Annuleren</p>
        </NuxtLink>
        <NuxtLink
          v-else
          data-test-id="fund-previous-step"
          class="my-4 w-full text-center text-blue-900 hover:underline sm:my-0 sm:w-fit"
          @click="previousStepClicked"
        >
          <p>Vorige stap</p>
        </NuxtLink>
        <AppButton
          v-if="route.path.includes('deelname') || route.path.includes('documenten')"
          data-test-id="fund-next-step"
          :is-disabled="isButtonDisabled"
          class="mb-8 w-full sm:mb-0 sm:w-fit"
          @click="nextStepClicked"
        >
          Volgende stap
          <template #icon-end>
            <FontAwesomeIcon :icon="['fas', 'arrow-right']" />
          </template>
        </AppButton>
      </div>
      <FundFooter v-if="!shouldPayOrPayed && currentFund" :fund="currentFund" :class="'self-end justify-self-end'" />
    </div>
  </div>
</template>
