<script setup lang="ts">
import { computed } from 'vue';
import { Fund } from '@graphql/types/fund';
import { downloadPdf, useToast } from '@mogelijk-technologies/ui-library';

export interface Props {
  fund: Fund;
}

const props = defineProps<Props>();

const fundDocumentsUrls = computed(() => {
  return {
    conditions: props.fund.fund_conditions?.absolute_url ?? '',
    brochure: props.fund.brochure?.absolute_url ?? '',
  };
});

const download = async (url: string) => {
  try {
    await downloadPdf(url, undefined);
  } catch {
    const toast = await useToast({
      theme: 'warning',
      title: 'Er is iets fout gegaan',
      message: 'Het bestand kon niet geopend worden.',
    });
    toast.present();
  }
};
</script>

<template>
  <div v-if="fundDocumentsUrls.conditions && fundDocumentsUrls.brochure">
    Bekijk hier de
    <template v-if="fundDocumentsUrls.conditions">
      <a
        data-test-id="fund-footer-conditions"
        :href="fundDocumentsUrls.conditions"
        class="text-blue-900 hover:underline"
        title="fondsvoorwaarden"
        @click.prevent="download(fundDocumentsUrls.conditions)"
      >
        fondsvoorwaarden
      </a>
      en
    </template>
    <a
      v-if="fundDocumentsUrls.brochure"
      data-test-id="fund-footer-brochure"
      :href="fundDocumentsUrls.brochure"
      class="text-blue-900 hover:underline"
      title="brochure"
      @click.prevent="download(fundDocumentsUrls.brochure)"
    >
      brochure
    </a>.
  </div>
</template>
